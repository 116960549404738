import './QuickEntryTeaserComponent.scss';

import React, { useContext, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import SiteDataContext from '../../contexts/SiteDataContext';
// import Car from '../../styles/assets/images/static-vhv_telematik_teaser_final.webp';
import Minigame from '../../styles/assets/images/static-Teaserbild-MiniGame.webp';
import Lightbox from '../Lightbox/Lightbox';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import { AutoHeight } from '../ui/AutoHeight/AutoHeight';
import Container from '../ui/Container/Container';
import Icon from '../ui/Icon/Icon';
import Image from '../ui/Image/Image';
import Link from '../ui/Link/Link';
import YouTubeComponent from '../YouTubeComponent/YouTubeComponent';

const QuickEntryTeaserComponent = () => {
  const { navs = {}, pageScope } = useContext(SiteDataContext) || {};
  const nav = navs && pageScope && navs[pageScope] ? navs[pageScope] : [];
  const allProductsDoorPage = nav.find(mainNavItem => {
    const { template } = mainNavItem || {};
    return template === 'AllProductsDoorPage';
  });

  const [selectedInsurance, setSelectedInsurance] = useState<number>(null);
  const [showDetails, setShowDetails] = useState(false);
  const { children: privateMainNavItems } = allProductsDoorPage || {};
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);

  const handleSelectCategory = index => {
    if (Object.prototype.hasOwnProperty.call(privateMainNavItems[index], 'children')) {
      setSelectedInsurance(index);
      setShowDetails(true);
    }
  };

  // const telematikLink = '/versicherungen/kfz-versicherung/auto/tarifrechner/';
  const gameLink = 'https://www.vhv.de/telematik-minigame';

  const telematikVideoId = 'By7pD92WsBQ';

  return (
    <Container>
      <AutoHeight>
        <Lightbox
          visible={isLightboxVisible}
          variants={['video']}
          turnOffHandler={() => setIsLightboxVisible(false)}
        >
          <YouTubeComponent id={telematikVideoId} />
        </Lightbox>
        <div className="QuickEntryTeaserComponent">
          <div className="QuickEntryTeaserComponent__telematicCard">
            <Image src={Minigame} />
            <h3>
              So <strong>sparen</strong> Sie <strong>bis zu 30%</strong> in der VHV Autoversicherung
            </h3>
            <p>
              Wir belohnen einen sicheren Fahrstil! Wie genau das funktioniert? Jetzt TELEMATIK
              spielerisch kennenlernen.
            </p>
            <div className="QuickEntryTeaserComponent__telematicCardButtonWrapper">
              <NewButtonComponent
                icon="gamepad"
                linkTo={gameLink}
                linkType="external"
                label="Zum Spiel"
              />
              <NewButtonComponent
                icon="video-play"
                label="Telematik in 2 Minuten erklärt"
                variant="light"
                onClick={() => setIsLightboxVisible(true)}
              />
            </div>
          </div>
          <div className="QuickEntryTeaserComponent__insuranceSection">
            <h2>
              <strong>Was</strong> möchten Sie <strong>versichern</strong>?
            </h2>
            <div>
              <CSSTransition in={!showDetails} timeout={200}>
                <div className="QuickEntryTeaserComponent__insurances">
                  {privateMainNavItems &&
                    privateMainNavItems.slice(0, 5).map((insurance, index) => {
                      const directLink = Object.prototype.hasOwnProperty.call(insurance, 'children')
                        ? ''
                        : insurance.path;
                      return directLink ? (
                        <Link
                          key={index}
                          linkTo={directLink}
                          extraClass="QuickEntryTeaserComponent__insuranceOverviewRow"
                        >
                          <Icon
                            className="QuickEntryTeaserComponent__categoryIcon"
                            newSprites={true}
                            type={insurance.icon}
                          />
                          {insurance.title}
                          <Icon type="arrow-line-right" />
                        </Link>
                      ) : (
                        <button
                          key={index}
                          className="QuickEntryTeaserComponent__insuranceOverviewRow"
                          onClick={() => handleSelectCategory(index)}
                        >
                          <Icon
                            className="QuickEntryTeaserComponent__categoryIcon"
                            newSprites={true}
                            type={insurance.icon}
                          />
                          {insurance.title}
                          <Icon type="arrow-line-right" />
                        </button>
                      );
                    })}
                  <NewButtonComponent
                    linkTo="/versicherungen"
                    icon="arrow-line-right"
                    label="Alle Versicherungen entdecken"
                    variant="light"
                  />
                </div>
              </CSSTransition>
              <CSSTransition in={showDetails} timeout={200}>
                <div className="QuickEntryTeaserComponent__insuranceDetail">
                  <Icon type="arrow-line-left" onClick={() => setShowDetails(false)} />
                  <div>
                    {privateMainNavItems?.map((insuranceClass, insuranceIndex) => {
                      const isSelected = selectedInsurance === insuranceIndex;
                      return (
                        <div
                          key={insuranceIndex}
                          className={`
                            QuickEntryTeaserComponent__insuranceClass${
                              isSelected
                                ? ' QuickEntryTeaserComponent__insuranceClass--selected'
                                : ''
                            }
                          `.trim()}
                        >
                          {insuranceClass.children?.map(insurance => {
                            return (
                              <Link
                                key={insurance.id}
                                linkTo={insurance.path}
                                extraClass="QuickEntryTeaserComponent__insuranceOverviewRow"
                              >
                                <Icon
                                  className="QuickEntryTeaserComponent__categoryIcon"
                                  newSprites={true}
                                  type={insurance.icon}
                                />
                                {insurance.title}
                              </Link>
                            );
                          })}
                          <NewButtonComponent
                            linkTo={privateMainNavItems[selectedInsurance]?.path}
                            icon="arrow-line-right"
                            label={privateMainNavItems[selectedInsurance]?.navigationTitle}
                            variant="light"
                          />
                        </div>
                      );
                    })}
                    {/* {selectedInsurance != null && (
                      <>
                        {privateMainNavItems[selectedInsurance].children.map(
                          (insuranceDetail, index) => {
                            return (
                              <Link key={index} linkTo={insuranceDetail.path}>
                                <div className="QuickEntryTeaserComponent__insuranceOverviewRow">
                                  <Icon
                                    className="QuickEntryTeaserComponent__categoryIcon"
                                    newSprites={true}
                                    type={insuranceDetail.icon}
                                  />
                                  <div>{insuranceDetail.title}</div>
                                </div>
                              </Link>
                            );
                          }
                        )}
                      </>
                    )} */}
                  </div>
                </div>
              </CSSTransition>
            </div>
          </div>
        </div>
      </AutoHeight>
    </Container>
  );
};

export default QuickEntryTeaserComponent;
